<template>
  <div class="redirector">
    <!--{{ qr_redirect }}-->
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "redirector",
  data() {
    return {
      loading: true,
      qr_redirect: {},
    };
  },
  methods: {
    getQrRedirect() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/qr_redirects/" + this.$route.params.id)
        .then((response) => {
          this.qr_redirect = response.data.coupon;
          this.redirectToUrl(process.env.VUE_APP_BASE_APP+"/coupons/form/"+this.$route.params.id)
        });
    },
    redirectToUrl(url) {
      axios
        .patch(process.env.VUE_APP_BASE_API + "/qr_redirects/" + this.$route.params.id, { redirected: true })
        .then(() => {
          window.location.replace(url);
        })
    }
  },
  mounted() {
    this.getQrRedirect();
  },
};
</script>

<style lang="scss">

  .redirector {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #fff;
    z-index: 99999;
  }

</style>
